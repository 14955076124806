/*=============================================
=            backgrounds            =
=============================================*/

/* background images */

.funfact-bg {
  background-image: url('/assets/img/backgrounds/funfact-bg.jpg');
}

.testimonial-slider-area-bg {
  background-image: url('/assets/img/backgrounds/testimonial.jpg');
}

.breadcrumb-bg {
  background-image: url('/assets/img/backgrounds/funfact-bg.jpg');
}

.support-bg {
  background-image: url('/assets/img/support/support-bg.jpg');
}

.contact-bg {
  background-image: url('/assets/img/backgrounds/support-bg.jpg');
}

.services-bg {
  background-image: url('/assets/img/service/services-bg.jpg');
}

.it-support-bg {
  background-image: url('/assets/img/service/it-support-bg.jpg');
}

.audiovisual-bg {
  background-image: url('/assets/img/service/audiovisual-bg.jpg');
}

.avrental-bg {
  background-image: url('/assets/img/service/avrental-bg.jpg');
}

.avsales-bg {
  background-image: url('/assets/img/service/avsales-bg.jpg');
}

.video-surveillance-bg {
  background-image: url('/assets/img/service/video-surveillance-bg.jpg');
}

.software-development-bg {
  background-image: url('/assets/img/service/software-development-bg.jpg');
}

.small-business-bg {
  background-image: url('/assets/img/solutions/small-business-bg.jpg');
}

.education-bg {
  background-image: url('/assets/img/solutions/education-bg.jpg');
}

.worship-bg {
  background-image: url('/assets/img/solutions/worship-bg.jpg');
}

.avintegration-bg {
  background-image: url('/assets/img/service/avintegration-bg.jpg');
}

.white-bg {
  background-color: #ffffff;
}

.grey-bg {
  background-color: #f7f7f7;
  &--style2 {
    background-color: #eeeeee;
  }
  &--style3 {
    background-color: #f5f6fa;
  }
}

.dark-bg {
  background-color: #111;
  &--style2 {
    background-color: #11202d;
  }
  &--style3 {
    background-color: $theme-color--black;
  }
  &--style4 {
    background-color: #06112c;
  }
  &--style5 {
    background-color: #05103b;
  }
}

.default-bg {
  background-color: $theme-color--default;
}

/*=====  End of backgrounds  ======*/
